import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import loadable from "@loadable/component";
import styled from "styled-components";
import Layout from "../components/Layout";
import PostPreview from "../components/PostPreview";

const Pagination = loadable(() => import("../components/ArchivePagination"));

const blogCategories = [
  {
    name: "Artikkelit",
    link: "/artikkelit/",
  },
];

const metadata = {
  artikkelit: {
    title: "Uutiset & Artikkelit: Puhuttavat rahapeli-aiheet",
    description:
      "Kaikki mitä sinun tulee tietää rahapeleistä. Tuoreita uutisia, mielipiteitä jakavia artikkeleita ja ohjeita rahapeleissä pärjäämiseen.",
  },
};

const BlogListing = ({ data, pageContext }) => {
  const posts = data.allContentfulBlogPost.edges;

  const metadataListing = metadata[pageContext.url];
  return (
    <Layout bodyClass="home blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support">
      <GatsbySeo
        title={metadataListing.title}
        description={metadataListing.description}
        canonical={`https://kasinotilmanlisenssia.com/${pageContext.url}/`}
      />
      <CategoriesNav>
        {blogCategories.map((categoryItem) => {
          return (
            <Link key={categoryItem.name} to={categoryItem.link} activeClassName="active-cat-link">
              {categoryItem.name}
            </Link>
          );
        })}
      </CategoriesNav>
      {posts &&
        posts.map((post, index) => {
          return <PostPreview key={index} post={post} isLast={index === posts.length - 1} />;
        })}
      <Pagination
        numPages={pageContext.numPages}
        currentPage={pageContext.currentPage}
        slugPrefix={`/${pageContext.url}`}
      />
    </Layout>
  );
};

const CategoriesNav = styled.div`
  display: flex;
  gap: 24px;
  margin: 48px auto;
  width: 100%;
  max-width: 370px;
  justify-content: center;

  @media (max-width: 997px) {
    margin: 24px auto 0;
  }

  .active-cat-link {
    color: rgb(0, 152, 100);
    position: relative;

    ::after {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      content: ".";
      color: transparent;
      background: rgb(0, 152, 100);
      height: 1px;
      transition: all 0.2s ease-in 0s;
    }
  }

  a {
    text-decoration: none;
    color: #000;
    font-size: 28px;

    @media (max-width: 997px) {
      font-size: 24px;
    }
  }
`;

export const pageQuery = graphql`
  query BlogListingQuery($skip: Int!, $limit: Int!, $category: [String]!) {
    allContentfulBlogPost(
      skip: $skip
      limit: $limit
      sort: { fields: createdDate, order: DESC }
      filter: { category: { in: $category } }
    ) {
      edges {
        node {
          slug
          body {
            body
          }
          category
          image {
            gatsbyImageData
          }
          title
          createdDate(formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`;

export default BlogListing;
